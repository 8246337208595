import './index.css'
import './App.css'
import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import Home from "./Home";
import {CircularProgress} from "@mui/material";


const supabase = createClient('https://bdoqvqzjnaibtotsyqsi.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJkb3F2cXpqbmFpYnRvdHN5cXNpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODIzNTQxNDQsImV4cCI6MTk5NzkzMDE0NH0.qmBoRrbpKk3RqImfi70L_OnrXXZYhvdbEAxzpIzCS4Q')

export default function App() {
  const [session, setSession] = useState(undefined)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (session === undefined){
    return(<div className={'loaderApp'}><CircularProgress/></div>)
  }
  else if (!session) {
    return (<Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa, style:{container: {maxWidth:'300px',margin: '100px auto 0 auto'/*,padding: '50px 10px 0 10px'*/ } }}} providers={[]} />)
  }
  else {
    return (<Home supa={supabase}/>)
  }
}
