import React, {useEffect, useState} from 'react';
import './Analytics.css';
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";

function Analytics({supa}) {

    // const [totals, setTotals] = useState({
    //     'Housing':0,
    //     'Transportation':0,
    //     'Food':0,
    //     'Saving':0,
    //     'Health':0,
    //     'Entertainment':0,
    //     'Misc':0
    // })
    const [totals,setTotals] = useState([])
    const [queryMonth,setQueryMonth] = useState((new Date()).getMonth())
    const [totalSpent,setTotalSpent] = useState(0)
    const categories= ['Housing','Transportation','Food','Saving','Health','Entertainment','Misc']
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    useEffect(() => {
        console.log('use effect run')
        async function getTotals() {
            const date = new Date();
            let firstDay = new Date(date.getFullYear(), queryMonth, 1);
            let lastDay = new Date(date.getFullYear(), queryMonth + 1, 0);


            const dateString1 = firstDay.toISOString();
            const dateString2 = lastDay.toISOString();
            console.log(dateString1)
            console.log(dateString2)

            //let totalsTemp = totals
            let totalsTemp = [0,0,0,0,0,0,0]
            for (const category in categories){
                console.log(categories[category])
                const { data, error } = await supa
                    .from('budget_items')
                    .select()
                    .eq('category',categories[category])
                    .gt('created_at',dateString1)
                    .lt('created_at',dateString2)
                console.log(data)
                console.log(error)
                //setTotals(data)
                for (let i  =0;i<data.length;i++){
                    totalsTemp[category] += data[i].amount;
                }
            }

            const sum = totalsTemp.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
            );

            setTotalSpent(sum)

            setTotals(totalsTemp)

        }
        getTotals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryMonth])

    const handleChange = (event) => {
        setQueryMonth(event.target.value);
        setTotals([])
    };

    return (
        <div className={'analyticsWrapper'}>
            <FormControl sx = {{paddingBottom:'10px'}} fullWidth>
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={queryMonth}
                    label="Select Month"
                    onChange={handleChange}
                >
                    {monthNames.map((month,index) => (
                        <MenuItem value={index}>{month}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <h1>Spending by Category for the month of {monthNames[(new Date()).getMonth()]}:</h1>
            {totals.length === 0 && <CircularProgress />}
            {totals.map((amt,index) => (
                <h3>{categories[index]}: ${amt.toFixed(2)}</h3>
            ))}
            {totals.length !== 0 && <h2 className={'totalSpent'}>Total Spent: ${totalSpent.toFixed(2)}</h2>}
        </div>
    )
}

export default Analytics;