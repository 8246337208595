import React, {useEffect, useState} from 'react';
import './Transaction.css';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    TextField
} from "@mui/material";

function Transaction({name,amount,primKey,supa,refresh,category}) {

    const [newName, setNewName] = useState(name)
    const [newAmount, setNewAmount] = useState(amount)
    const [newCategory, setNewCategory] = useState(category)
    const categories= ['Housing','Transportation','Food','Saving','Health','Entertainment','Misc']

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const deleteTransaction = async () => {
        const { error } = await supa
            .from('budget_items')
            .delete()
            .eq('id', primKey)
        console.log(error)
        refresh();
    }

    const update = async (name, amount, category) => {
        const regex = /^-?\d+(\.\d+)?$/;
        if (!regex.test(amount)) {
            console.log('The string contains other characters.');
            window.alert("make sure your amount contains only numbers with an optional decimal, ex: \"32.25\"")
            return;
        }

        let id = localStorage.getItem('sb-bdoqvqzjnaibtotsyqsi-auth-token')
        id = JSON.parse(id).user.id
        const itemAmountFloat = parseFloat(amount);
        if (Number.isNaN(itemAmountFloat)) {
            window.alert("input not valid, make sure your amount contains only numbers with an optional decimal, ex: \"32.25\"")
            return;
        }
        const itemAmount = itemAmountFloat.toFixed(2)
        console.log(id)
        const { error } = await supa
            .from('budget_items')
            .update({ name: name, amount: itemAmount, category:category,user_id: id })
            .eq('id',primKey)
        console.log(error)
        if (error === null ){
            setNewName('')
            setNewAmount('')
            setNewCategory('')
            refresh();
            handleClose()
        }
    }

    useEffect(() => {
        setNewName(name)
    }, [name])

    useEffect(() => {
        setNewAmount(amount)
    }, [amount])

    useEffect(() => {
        setNewCategory(category)
    }, [category])

    return (
        <Paper sx = {{background:'transparent',display:'flex',justifyContent:'space-between',marginBottom:'15px',padding:"0 5px 0 5px"}} elevation={3} >
            <div>
                <p style = {{fontWeight:'bold'}}>{name}</p>
                <p>${amount.toFixed(2)}</p>
            </div>
            <div style = {{display:'flex'}}>
                <Button onClick={handleOpen}>Update</Button>
                <Button onClick={deleteTransaction}>Delete</Button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx = {{background:'white', padding: "0 10px 10px 10px"}} >
                    <div className={'homeForms'}>
                        <TextField inputProps={{
                            autoCorrect: 'off', // Safari-only
                            spellCheck: false,
                        }} value={newName} onChange={(e) => setNewName(e.target.value)} sx = {{paddingBottom:'10px'}} id="filled-basic" label="Name" variant="filled" />
                        <TextField inputProps={{
                            autoCorrect: 'off', // Safari-only
                            spellCheck: false,
                        }} value={newAmount} onChange={(e) => setNewAmount(e.target.value)} sx = {{paddingBottom:'10px'}} id="filled-basic" label="Amount" variant="filled" />
                        {/*<TextField value={category} onChange={(e) => setCategory(e.target.value)} sx = {{paddingBottom:'10px'}} id="filled-basic" label="Category" variant="filled" />*/}
                        <FormControl sx = {{paddingBottom:'10px'}} fullWidth>
                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={newCategory}
                                label="Age"
                                onChange={(e) => setNewCategory(e.target.value)}
                            >
                                {categories.map((cat) => (
                                    <MenuItem value={cat}>{cat}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button disabled = {newCategory.length===0||name.length===0||amount.length===0} onClick={()=>update(newName,newAmount,newCategory)} variant="outlined">Submit</Button>
                    </div>
                </Box>
            </Modal>
        </Paper>
    )
}

export default Transaction;