import React, {useEffect, useState} from 'react';
import './Home.css';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@mui/material";
import Transaction from "./Transaction";
import Analytics from "./Analytics";

function Home({supa}) {

    const [name, setName] = useState('')
    const [rerun, setRerun] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [amount, setAmount] = useState('')
    const [category, setCategory] = useState('')
    const [transactions, setTransactions] = useState([])
    const categories= ['Housing','Transportation','Food','Saving','Health','Entertainment','Misc']
    const [checked, setChecked] = React.useState(false);
    const [limit, setLimit] = useState(10)
    const [updatePasswordPrompt, setUpdatePasswordPrompt] = useState(false)
    const [updatedPassword, setUpdatedPassword] = useState("")

    useEffect(() => {
        console.log('use effect run')
        async function getTranasctions() {
            const { data, error } = await supa
                .from('budget_items')
                .select()
                .order('created_at', {ascending:false})
                .limit(limit)
            console.log(data)
            console.log(error)
            setTransactions(data)
            if(data.length<10){
                setIsDisabled(true)
            }
        }
        getTranasctions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supa,rerun])

    const loadMore= () =>{
        async function getTranasctions() {
            const { data, error } = await supa
                .from('budget_items')
                .select()
                .order('created_at', {ascending:false})
                .range(limit,limit+9)
                //.limit(limit+10)
            console.log(data)
            console.log(error)
            setTransactions([...transactions,...data])
            if(data.length<10){
                setIsDisabled(true)
            }
        }
        getTranasctions();
        setLimit(limit+10)
    }

    function refresh() {
        setRerun(!rerun)
    }

    const signout = () =>{
        supa.auth.signOut()
    }

    const updatePasswordInternal = () =>{
        async function updatePassword() {
            const { data, error } = await supa.auth.updateUser({ password: updatedPassword })
            console.log(data)
            console.log(error)
            if(error) {
                window.alert(error.message)
            }
            setUpdatePasswordPrompt(false)
            setUpdatedPassword("")
        }
        updatePassword()
    }

    const updatePassword = () => {
        setUpdatePasswordPrompt(!updatePasswordPrompt)
    }

    const insert = async (name, amount, category) => {
        const regex = /^-?\d+(\.\d+)?$/;
        if (!regex.test(amount)) {
            console.log('The string contains other characters.');
            window.alert("make sure your amount contains only numbers with an optional decimal, ex: \"32.25\"")
            return;
        }

        let id = localStorage.getItem('sb-bdoqvqzjnaibtotsyqsi-auth-token')
        id = JSON.parse(id).user.id
        const itemAmountFloat = parseFloat(amount);
        if (Number.isNaN(itemAmountFloat)) {
            window.alert("input not valid, make sure your amount contains only numbers with an optional decimal, ex: \"32.25\"")
            return;
        }
        const itemAmount = itemAmountFloat.toFixed(2)
        console.log(id)
        const { error } = await supa
            .from('budget_items')
            .insert({ name: name, amount: itemAmount, category: category,user_id: id })
        console.log(error)
        if (error === null ){
            setName('')
            setAmount('')
            setCategory('')
            setRerun(!rerun)
        }
    }

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    const handleChangeCheck = (event) => {
        setChecked(event.target.checked);
    };



    return (
        <div className={"homeWrapper"}>
            <FormGroup sx = {{display: 'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <FormControlLabel sx ={{marginLeft:'0px'}} control={<Switch
                    checked={checked}
                    onChange={handleChangeCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                />} label="Switch view" />
                <div>
                    <Button sx ={{width:'200px'}} onClick={updatePassword}>Reset Password</Button>
                    <Button sx ={{width:'100px'}} onClick={signout}>Sign Out</Button>
                </div>
            </FormGroup>
            {updatePasswordPrompt && <TextField inputProps={{
                autoCorrect: 'off', // Safari-only
                spellCheck: false,
            }} value={updatedPassword} onChange={(e) => setUpdatedPassword(e.target.value)} sx = {{paddingBottom:'10px'}} id="filled-basic" label="New Password" variant="filled" />}
            {updatePasswordPrompt && <Button disabled = {updatedPassword.length === 0} onClick={updatePasswordInternal} variant="outlined">Submit</Button>}
            {!checked && <div className={'homeForms'}>
                <TextField inputProps={{
                    autoCorrect: 'off', // Safari-only
                    spellCheck: false,
                }} value={name} onChange={(e) => setName(e.target.value)} sx = {{paddingBottom:'10px'}} id="filled-basic" label="Name" variant="filled" />
                <TextField inputProps={{
                    autoCorrect: 'off', // Safari-only
                    spellCheck: false,
                }} value={amount} onChange={(e) => setAmount(e.target.value)} sx = {{paddingBottom:'10px'}} id="filled-basic" label="Amount" variant="filled" />
                {/*<TextField value={category} onChange={(e) => setCategory(e.target.value)} sx = {{paddingBottom:'10px'}} id="filled-basic" label="Category" variant="filled" />*/}
                <FormControl sx = {{paddingBottom:'10px'}} fullWidth>
                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        label="Age"
                        onChange={handleChange}
                    >
                        {categories.map((cat) => (
                            <MenuItem value={cat}>{cat}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button disabled = {category.length===0||name.length===0||amount.length===0} onClick={()=>insert(name,amount,category)} variant="outlined">Submit</Button>
            </div>}
            {checked && <Analytics supa = {supa}/>}
            <div className={'recentTransactions'}>
                <h1>Recent Transactions:</h1>
                {transactions.map((trans,index) => (
                    <Transaction key={index} name ={trans.name} amount={trans.amount} primKey={trans.id} supa={supa} refresh={refresh} category={trans.category}/>
                ))}
                <Button disabled={isDisabled} onClick={loadMore} sx = {{margin:'0 auto 0 auto'}}>Load More</Button>
            </div>
        </div>
    )
}

export default Home;